import PropTypes from "prop-types";
import clsx from "clsx";
import { createTestId } from "../../../utils/createTestId";

export function RadioButton({
  checked,
  className,
  disabled,
  id,
  inputClassName,
  inputProps,
  label,
  labelClassName,
  labelProps,
  name,
  onChange,
  onClick,
  required,
  showRequiredAsterisk = false,
  testId = "input",
  value,
  ...other
}) {
  return (
    <div className={clsx("FormComponent", "RadioButton", className)} {...other}>
      <input
        className={clsx("RadioButton__input", inputClassName)}
        type="radio"
        name={name}
        value={value}
        id={id}
        required={required}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        data-test-id={createTestId(testId)}
        onClick={onClick}
        {...inputProps}
      />
      <label className={clsx("RadioButton__label", labelClassName)} htmlFor={id} {...labelProps}>
        {label}
        {required && showRequiredAsterisk && "*"}
      </label>
    </div>
  );
}

RadioButton.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inputClassName: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelClassName: PropTypes.string,
  labelProps: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  showRequiredAsterisk: PropTypes.bool,
  testId: PropTypes.string,
  value: PropTypes.string,
};
