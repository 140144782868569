import PropTypes from "prop-types";
import clsx from "clsx";
import { createTestId } from "../../../utils/createTestId";

export function Checkbox({
  checked,
  className,
  defaultChecked,
  disabled,
  id,
  inputClassName,
  inputProps,
  label,
  labelClassName,
  labelProps,
  name,
  onChange,
  required,
  showRequiredAsterisk = false,
  testId = "input",
  value,
  ...other
}) {
  return (
    <div className={clsx("FormComponent", "Checkbox", className)} {...other}>
      <input
        className={clsx("Checkbox__input", inputClassName)}
        type="checkbox"
        name={name}
        value={value}
        id={id}
        required={required}
        disabled={disabled}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        data-test-id={createTestId(testId)}
        {...inputProps}
      />
      <label
        className={clsx(
          "Checkbox__label",
          label && "Checkbox__label--withLabelText",
          labelClassName,
        )}
        htmlFor={id}
        {...labelProps}
      >
        {label}
        {required && showRequiredAsterisk && "*"}
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  defaultChecked: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inputClassName: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelClassName: PropTypes.string,
  labelProps: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  showRequiredAsterisk: PropTypes.bool,
  testId: PropTypes.string,
  value: PropTypes.string,
};
