import PropTypes from "prop-types";
import clsx from "clsx";
import { createTestId } from "../../../utils/createTestId";

export function Select({
  children,
  className,
  defaultValue,
  disabled,
  id,
  inputClassName,
  inputProps,
  label,
  labelClassName,
  labelProps,
  name,
  onChange,
  placeholder,
  required,
  showRequiredAsterisk = false,
  size,
  testId = "input",
  value,
  variant,
  ...other
}) {
  return (
    <div
      className={clsx("FormComponent", size && `FormComponent--${size}`, "Select", className)}
      {...other}
    >
      {label && (
        <label
          className={clsx("FormComponent__label", "Select__label", labelClassName)}
          htmlFor={id}
          {...labelProps}
        >
          {label}
          {required && showRequiredAsterisk && "*"}
        </label>
      )}
      <select
        className={clsx(
          "FormComponent__input",
          "Select__input",
          variant && `Select__input--${variant}`,
          inputClassName,
        )}
        defaultValue={defaultValue}
        value={value}
        required={required}
        id={id}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        data-test-id={createTestId(testId)}
        {...inputProps}
      >
        {children}
      </select>
    </div>
  );
}

Select.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  labelProps: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showRequiredAsterisk: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "lg"]),
  testId: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(["outlined", "noBorder", "blueWithShadow", "whiteWithShadow"]),
};
