import PropTypes from "prop-types";
import clsx from "clsx";
import { createTestId } from "../../../utils/createTestId";
import { useEffect, useRef } from "react";

export function TextArea({
  className,
  defaultValue,
  disabled,
  id,
  inputClassName,
  inputProps,
  label,
  labelClassName,
  labelProps,
  name,
  onChange,
  optional,
  placeholder,
  required,
  showRequiredAsterisk = false,
  testId = "input",
  value,
  autoHeight,
  ...other
}) {
  const textareaRef = useRef(null);

  function handleInput() {
    this.style.height = "auto";
    this.style.height = `${this.scrollHeight}px`;
  }

  useEffect(() => {
    if (autoHeight === "true") {
      const textarea = textareaRef.current;
      textarea.addEventListener("input", handleInput);

      return () => {
        textarea.removeEventListener("input", handleInput);
      };
    }
  }, []);

  return (
    <div className={clsx("FormComponent", "TextArea", className)} {...other}>
      {label && (
        <label
          className={clsx(
            "FormComponent__label",
            "TextArea__label",
            labelClassName,
            optional && "TextArea__label--flex",
          )}
          htmlFor={id}
          {...labelProps}
        >
          {label}
          {required && showRequiredAsterisk && "*"}
          {optional && <span className="TextArea__label--optional">Optional</span>}
        </label>
      )}
      <textarea
        ref={autoHeight === "true" ? textareaRef : undefined}
        className={clsx("FormComponent__input", "TextArea__input", inputClassName)}
        value={value}
        defaultValue={defaultValue}
        required={required}
        id={id}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        data-test-id={createTestId(testId)}
        {...inputProps}
      />
    </div>
  );
}

TextArea.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  labelProps: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  optional: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showRequiredAsterisk: PropTypes.bool,
  testId: PropTypes.string,
  value: PropTypes.string,
};
