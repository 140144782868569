import { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { FiAlertCircle } from "react-icons/fi";
import { createTestId } from "../../../utils/createTestId";
import { AnimatePresence, motion } from "framer-motion";
import { animatePresenceConfig } from "../../../config/appConfig";

export const TextField = forwardRef(
  (
    {
      autoComplete,
      className,
      defaultValue,
      disabled,
      id,
      inputClassName,
      inputProps,
      startAdornment,
      endAdornment,
      adornmentClassName,
      label,
      labelClassName,
      labelProps,
      name,
      onChange,
      onFocus,
      placeholder,
      required,
      showRequiredAsterisk = false,
      size,
      helperText,
      testId = "input",
      type = "text",
      value,
      variant,
      ...other
    },
    ref,
  ) => {
    return (
      <div
        className={clsx(
          "FormComponent",
          size && `FormComponent--${size}`,
          "TextField",
          variant && `TextField--${variant}`,
          startAdornment && "TextField__withAdornment--left",
          endAdornment && "TextField__withAdornment--right",
          className,
        )}
        {...other}
      >
        {label && (
          <label
            className={clsx("FormComponent__label", "TextField__label", labelClassName)}
            htmlFor={id}
            {...labelProps}
          >
            {label}
            {required && showRequiredAsterisk && "*"}
          </label>
        )}
        {startAdornment && (
          <div
            className={clsx(
              "TextField__adornment",
              "TextField__adornment--start",
              adornmentClassName,
            )}
          >
            {startAdornment}
          </div>
        )}
        {endAdornment && (
          <div
            className={clsx(
              "TextField__adornment",
              "TextField__adornment--end",
              adornmentClassName,
            )}
          >
            {endAdornment}
          </div>
        )}
        <input
          className={clsx("FormComponent__input", "TextField__input", inputClassName)}
          type={type}
          defaultValue={defaultValue}
          value={value}
          required={required}
          id={id}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={onFocus}
          autoComplete={autoComplete}
          data-test-id={createTestId(testId)}
          data-cy={name}
          ref={ref}
          {...inputProps}
        />
        <AnimatePresence>
          {helperText && (
            <motion.div {...animatePresenceConfig}>
              <div className="TextField__helperText">
                <FiAlertCircle />
                <span>{helperText}</span>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  },
);

TextField.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  id: PropTypes.string,
  inputClassName: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  labelProps: PropTypes.object,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showRequiredAsterisk: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "lg"]),
  startAdornment: PropTypes.node,
  testId: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf([
    "default",
    "gray",
    "withShadow",
    "whiteWithShadow",
    "defaultWithShadow",
    "searchbar",
    "searchbarSmall",
  ]),
};
